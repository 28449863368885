import { NEXT_PUBLIC_SITEIMPROVE_ID } from 'application/adapters/cms/_constants';
import Script from 'next/script';
import { useRouter } from 'next/router'
import { useEffect } from 'react';
import getConfig from 'next/config';



export const SiteImprove: React.FC = () => {
	const {publicRuntimeConfig} = getConfig()
	const router = useRouter();
	var siteImproveId = publicRuntimeConfig.NEXT_PUBLIC_SITEIMPROVE_ID;
	const trackPageview = (ref, url, title) => {
	// @ts-ignore
	  if(Cookiebot.consent.statistics) {
		try {
			// Use Custom Visit Tracking for Siteimprove
			// https://help.siteimprove.com/support/solutions/articles/80000448441-siteimprove-analytics-custom-visit-tracking
			// Set the ref explicitly for Siteimprove to track properly
			// @ts-ignore 
			_sz.push(["trackdynamic",{
				"url": url,
				"ref": ref || document.referrer,
				"title": title,
			}]);
		} catch (error) {
		  // console.error(error);
		}
	  }
	}

	useEffect(() => {
		const handleRouteChangeStart = () => {
		  // Save the current url for later in the window object
		  // Storing in the window scope is not very pretty but at the time
		  // the only way I could solve this
		  // @ts-ignore
		  window.trackRef = window.location.href;
		}
	
		const handleRouteChangeComplete = () => {
		  // When ever App route has changed
		  // track a pageview with Siteimprove with the
		  // previous and current url
		  // @ts-ignore
		  const trackRef = window.trackRef;
		  const trackUrl = window.location.href;
		  const trackTitle = document.title;
		  trackPageview(trackRef, trackUrl, trackTitle);
		}

		const handleCookieConsentChange = () => {
            // @ts-ignore
            if (window.Cookiebot?.consent?.statistics) {
                const sz = document.createElement('script');
                sz.type = 'text/javascript';
                sz.async = true;
                sz.dataset.cookieconsent = 'statistics'
                sz.src = `https://siteimproveanalytics.com/js/siteanalyze_${siteImproveId}.js`;
                const s = document.getElementsByTagName('script')[0];
                s.parentNode.insertBefore(sz, s);
            }
        };
	
		router.events.on('routeChangeStart', handleRouteChangeStart);
		router.events.on('routeChangeComplete', handleRouteChangeComplete);

		// Add cookie consent event listeners
        window.addEventListener('CookiebotOnAccept', handleCookieConsentChange);
	
		// If the component is unmounted, unsubscribe
		// from the event with the `off` method:
		return () => {
		  router.events.off('routeChangeStart', handleRouteChangeStart);
		  router.events.off('routeChangeComplete', handleRouteChangeComplete);
		  window.removeEventListener('CookiebotOnAccept', handleCookieConsentChange);
		}
	  }, []);

	const siteImproveScript = `
		(function() {
			var sz = document.createElement('script');
			sz.type = 'text/javascript';
			sz.async = true;
			sz.dataset.cookieconsent = 'statistics'
			sz.src = 'https://siteimproveanalytics.com/js/siteanalyze_${siteImproveId}.js';
			var s = document.getElementsByTagName('script')[0];
			if(Cookiebot.consent.statistics) {
				s.parentNode.insertBefore(sz, s);
			};
		})();
	`;
	return (
		<Script
			id="Siteimprove"
			strategy="lazyOnload"
			dangerouslySetInnerHTML={{
			__html: `${siteImproveScript}`,
			}}
		></Script>
	);
};
